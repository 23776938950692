/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"  
import hero from "../images/gero1.jpg";

const Bio = ({ artikuluCount }) => {
  return (
    <div className="bio" style={{
      background: `linear-gradient(0deg, rgba(232, 214, 155, 0.3), rgba(232, 214, 155, 0.3)), url(${hero})`,
      textAlign: 'center',
      minHeight: '100%',
      padding: '10em 0',
      marginBottom: '2em',
      backgroundSize: 'cover',
      backgroundPosition: 'center -150px',
      width: '100%'
    }}>
      <div style={{ boxShadow: '0px 0px 25px #555'}} className="w-4/5 md:w-1/2 mx-auto p-5 bg-white">
      <h1 style={{textAlign: 'center', fontSize: '2.2em'}}>Ongi etorri Gizapediara</h1>
      <br />
      <h2 style={{fontSize: '1.75em'}}>Giza eta gizarte zientzien entziklopedia: filosofia, politika, ekonomia, estatistika, ..., euskaraz</h2>
      <hr></hr>
      <span className="mt-2 block font-bold">Egun, {artikuluCount} artikulu dauzkagu Gizapedian.</span>
      </div>
    </div>
  )
}

export default Bio
