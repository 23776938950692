import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = 'Gizapedia'
  const posts = data.allWpPost.nodes
  const kategoriak = data.allWpCategory.nodes
  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="Gizapedia" />
        <Bio artikuluCount={posts.length - 1484} />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Ongi etorri Gizapediara" />
      <Bio  artikuluCount={data.allWpPost.totalCount - 1484} />
      <div className="grid md:grid-cols-2 gap-2 grid-cols-1">


        <div className="azala-kategoriak" style={{
          maxHeight: '400px',
          overflowY: 'scroll'
        }}>
          <h2 style={{fontSize: '2em', margin: '1em 0', textAlign: 'center'}}>Kategoriak</h2>
          <div className="grid grid-cols-1 gap-2 md:grid-cols-2 azala-kategoriak">
            {kategoriak.filter(function(kat) {
              return !['Español', 'Français', 'Català'].includes(kat.name)
            }).map((kategoria) => {
              return (
                <div className="text-center uppercase" style={{borderBottom: '1px solid #eee', paddingBottom: '1em', fontSize: '0.85em' }} key={kategoria.slug}>
                        <Link style={{ color: '#555 !important' }} to={'/kategoria/' + kategoria.slug} itemProp="url">
                          <span itemProp="headline">{kategoria.name}</span>
                        </Link>
                </div>
              )
            })}
          </div>
        </div>


        <div className="hiztegia-azala" style={{
          padding: '4em 2.5em',
          marginTop: '0em',
          backgroundImage: 'url(hi2.jpg)',
          backgroundSize: 'cover'
        }}>
          
          <h3 style={{fontSize: '1.7em', marginBottom: '0.5em'}}>Gizapedia hiztegia</h3>
          <p style={{fontSize: '1.25em', fontWeight: 'normal'}}>Euskarazko edozein hitzen definizio argia eta laburra bilatzen al duzu? Gizapediak hiztegia ere badu, egunez egun eguneratua eta osatua, hitzen kategoria gramatikala, erabilera-esparruak, tesauroak., itzulpenak, marka lexikografikoekin eta laster ere audioekin. Murgildu zaitez gurekin euskararen itsasoan.</p>
          <Link to="https://hiztegia.gizapedia.org" style={{
            padding: '0.5em 1.25em', border: '1px solid #111', borderRadius: '4px',color: '#111', fontWeight: 'bold', textAlign: 'center'
            }}>Hiztegia arakatu</Link>
        </div>
      </div>

        <h2 style={{fontSize: '2em', margin: '2em 0 0', textAlign: 'center'}}>Azken artikuluak</h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {posts.map((post, index) => {
          if (index > 5) return;
          const title = post.title || post.slug

          return (
            <div key={post.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={post.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{new Date(post.date).toLocaleDateString('eu-ES')}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.excerpt || post.description,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </div>
          )
        })}
        </div>
        <div className="ikus-banner">
          <h2>¿Buscas contenido en español? Visita Ikusmira, nuestro nuevo proyecto enciclopédico y educativo.</h2>
          <p>Ikusmira es la plataforma donde encontrar el contenido que buscas, asociado a un término concreto o un área de conocimiento más general. Con una visión análoga al proyecto de Gizapedia, Ikusmira se centra en las ciencias humanas y sociales. Entra, navega, aprende y si no encuentras lo que buscas, haz una propuesta de contenido acorde. Estamos para ayudarte y para ayudarte no paramos de crecer.</p>
          <a href="https://ikusmira.org">Descubrir</a>
        </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    allWpPost(sort: {order: DESC, fields: modified}, limit: 6) {
      nodes {
        content
        date
        excerpt
        featuredImage {
          node {
            mediaItemUrl
          }
        }
        guid
        slug
        status
        title
        uri
      }
      totalCount
    }
    allWpCategory {
      nodes {
        id
        uri
        slug
        count
        name
      }
    }
  }
`
